import type { VariantProps } from '@stitches/react';
import React from 'react';

import { keyframes, styled } from '@/stitches.config';

const ShimmerAnimation = keyframes({
  '100%': { transform: 'translateX(100%)' },
});

export const SkeletonWrapper = styled('div', {
  position: 'absolute',
  inset: '0',
  width: '0px',
  height: '0px',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  transition: 'opacity 1s var(--transition-easing)',
  pointerEvents: 'none',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    transform: 'translateX(-100%)',
  },
  '&.skeleton': {
    opacity: '0',
  },
  variants: {
    fadeIn: {
      true: {
        '&::before': {
          animation: `${ShimmerAnimation} 2s infinite`,
        },
      },
    },
    fadeOut: {
      true: {
        opacity: 0,
        '&::before': {
          animationPlayState: 'paused !important',
        },
      },
      false: {
        opacity: 1,
      },
    },
    placeholder: {
      shimmer: {
        backgroundColor: '$gray300',
        '&::before': {
          backgroundImage:
            'linear-gradient(90deg, transparent, $white, transparent)',
        },
      },
      'dark-shimmer': {
        backgroundColor: '$gray900',
        '&::before': {
          backgroundImage:
            'linear-gradient(90deg, transparent, $gray1000, transparent)',
        },
      },
    },
  },
  defaultVariants: {
    placeholder: 'shimmer',
  },
});

interface SkeletonProps {
  fadeIn?: VariantProps<typeof SkeletonWrapper>['fadeIn'];
  fadeOut?: VariantProps<typeof SkeletonWrapper>['fadeOut'];
  placeholder?: VariantProps<typeof SkeletonWrapper>['placeholder'];
}

export const Skeleton: React.FC<SkeletonProps> = ({
  fadeIn,
  fadeOut,
  placeholder,
}) => (
  <SkeletonWrapper
    fadeIn={fadeIn}
    fadeOut={fadeOut}
    placeholder={placeholder}
  />
);
