import { styled } from '@/stitches.config';

export const Img = styled('img', {
  maxInlineSize: '100%',
  blockSize: 'auto',
  variants: {
    layout: {
      fill: {
        position: 'absolute',
        inset: '0px',
        width: '0px',
        height: '0px',
        minWidth: '100%',
        maxWidth: '100%',
        minHeight: '100%',
        maxHeight: '100%',
      },
      fixed: {
        position: 'relative',
        display: 'inline-block',
        maxWidth: 'none',
      },
      responsive: {
        position: 'relative',
        display: 'block',
        width: '100%',
      },
    },
    loadEffect: {
      none: {},
      fade: {
        opacity: '0',
        transition:
          'opacity 1s var(--transition-easing), transform 1s var(--transition-easing)',
        '&.is-loaded': {
          opacity: '1',
        },
      },
    },
    hoverEffect: {
      zoom: {
        cursor: 'pointer',
        transition:
          'opacity 1s var(--transition-easing), transform 1s var(--transition-easing)',
        '@hover': {
          '&:hover, [data-hover]:hover &': {
            transform: 'scale(1.05)',
          },
        },
      },
    },
    objectFit: {
      cover: {
        objectFit: 'cover',
      },
      contain: {
        objectFit: 'contain',
      },
      none: {
        objectFit: 'none',
      },
    },
    objectPosition: {
      center: {
        objectPosition: 'center',
      },
      top: {
        objectPosition: 'top',
      },
      bottom: {
        objectPosition: 'bottom',
      },
      left: {
        objectPosition: 'left',
      },
      right: {
        objectPosition: 'right',
      },
      'top-left': {
        objectPosition: 'top left',
      },
      'top-right': {
        objectPosition: 'top right',
      },
      'bottom-left': {
        objectPosition: 'bottom left',
      },
      'bottom-right': {
        objectPosition: 'bottom right',
      },
      'left-top': {
        objectPosition: 'left top',
      },
      'left-bottom': {
        objectPosition: 'left bottom',
      },
      'right-top': {
        objectPosition: 'right top',
      },
      'right-bottom': {
        objectPosition: 'right bottom',
      },
    },
    aspectRatio: {
      '1by1': {
        aspectRatio: '1 / 1',
      },
      '1by2': {
        aspectRatio: '1 / 2',
      },
      '2by1': {
        aspectRatio: '2 / 1',
      },
      '2by3': {
        aspectRatio: '2 / 3',
      },
      '3by2': {
        aspectRatio: '3 / 2',
      },
      '3by4': {
        aspectRatio: '3 / 4',
      },
      '4by3': {
        aspectRatio: '4 / 3',
      },
      '4by5': {
        aspectRatio: '4 / 5',
      },
      '5by4': {
        aspectRatio: '5 / 4',
      },
      '9by16': {
        aspectRatio: '9 / 16',
      },
      '16by9': {
        aspectRatio: '16 / 9',
      },
      '21by9': {
        aspectRatio: '21 / 9',
      },
      '8by3': {
        aspectRatio: '8 / 3',
      },
    },
  },
});

export const Picture = styled('picture', {
  display: 'inline-block',
  overflow: 'hidden',
  variants: {
    layout: {
      fill: {
        display: 'block',
        '&, img': {
          position: 'absolute',
          inset: '0px',
        },
        '&': {
          width: 'initial',
          height: 'initial',
        },
        img: {
          width: '0px',
          height: '0px',
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        },
      },
      fixed: {
        position: 'relative',
        display: 'inline-block',
        img: {
          maxWidth: 'none',
        },
      },
      responsive: {
        position: 'relative',
        display: 'block',
        width: '100%',
        img: {
          position: 'absolute',
          inset: '0px',
          width: '0px',
          height: '0px',
          minWidth: '100%',
          maxWidth: '100%',
          minHeight: '100%',
          maxHeight: '100%',
        },
      },
    },
    aspectRatio: {
      '1by1': {
        aspectRatio: '1 / 1',
      },
      '1by2': {
        aspectRatio: '1 / 2',
      },
      '2by1': {
        aspectRatio: '2 / 1',
      },
      '2by3': {
        aspectRatio: '2 / 3',
      },
      '3by2': {
        aspectRatio: '3 / 2',
      },
      '3by4': {
        aspectRatio: '3 / 4',
      },
      '4by3': {
        aspectRatio: '4 / 3',
      },
      '4by5': {
        aspectRatio: '4 / 5',
      },
      '5by4': {
        aspectRatio: '5 / 4',
      },
      '9by16': {
        aspectRatio: '9 / 16',
      },
      '16by9': {
        aspectRatio: '16 / 9',
      },
      '21by9': {
        aspectRatio: '21 / 9',
      },
      '8by3': {
        aspectRatio: '8 / 3',
      },
    },
  },
});
