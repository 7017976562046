import type { VariantProps } from '@stitches/react';

import { Img } from '@/components/shared/media/image/Image.styles';
import { SkeletonWrapper } from '@/components/shared/media/image/skeleton';
import { CSS } from '@/stitches.config';

export const DEFAULT_IMAGE =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

export type ImageLoadingType = 'lazy' | 'eager';

export type ImageFitType = 'bounds' | 'crop';

export type ImageOrientationType = 'portrait' | 'landscape';

export type ImageSoureDeviceType = 'mobile' | 'desktop' | 'both';

export type ImageAspectRatioType =
  | '8:3'
  | '21:9'
  | '2:1'
  | '16:9'
  | '3:2'
  | '4:3'
  | '5:4'
  | '1:1'
  | '4:5'
  | '3:4'
  | '2:3'
  | '9:16'
  | '1:2'
  | '9:4'
  | '9:8'
  | '3:1'
  | string;

export interface ImageOptions {
  width?: number;
  height?: number;
  crop?: ImageAspectRatioType;
  fit?: ImageFitType;
  trim?: number | string;
  blur?: number;
  quality?: number;
  dpr?: number;
}

export interface MediaConditions {
  minWidth?: number;
  maxWidth?: number;
  orientation?: ImageOrientationType;
}

export interface Source {
  srcset: string;
  media?: MediaConditions;
  options?: ImageOptions;
  device?: ImageSoureDeviceType;
}

export interface ImageProps {
  css?: CSS;
  className?: string;
  src: string;
  alt: string;
  width: number;
  height: number;
  sources?: Source[];
  priority?: boolean;
  rootRef?: HTMLElement | null;
  rootMargin?: string;
  placeholder?: VariantProps<typeof SkeletonWrapper>['placeholder'];
  loading?: ImageLoadingType;
  layout?: VariantProps<typeof Img>['layout'];
  objectFit?: VariantProps<typeof Img>['objectFit'];
  objectPosition?: VariantProps<typeof Img>['objectPosition'];
  loadEffect?: VariantProps<typeof Img>['loadEffect'];
  hoverEffect?: VariantProps<typeof Img>['hoverEffect'];
  aspectRatio?: VariantProps<typeof Img>['aspectRatio'];
  isMaxWidth?: boolean;
  onLoad?: () => void;
  srcSetOptions?: ImageOptions; // This is for the <img> srcset pattern
  sizes?: string; // This is for the <img> sizes attribute
  useSrcSetPattern?: boolean; // This prop will toggle between picture and img patterns
}
