import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  DEFAULT_IMAGE,
  ImageProps,
} from '@/components/shared/media/image/Image.props';
import { Img, Picture } from '@/components/shared/media/image/Image.styles';
import {
  createImageQueryParams,
  createImgSrcSetElement,
  createPreloadLinks,
  createSourceElement,
  determineImageSrc,
  isSvg,
} from '@/components/shared/media/image/Image.utils';
import { Skeleton } from '@/components/shared/media/image/skeleton';
import { useInViewRef } from '@/components/shared/utility/hooks';

export const Image = ({
  css = {},
  className = '',
  src = '',
  alt = '',
  width,
  height,
  sources = [],
  placeholder,
  loading = 'lazy',
  loadEffect = 'fade',
  priority = false,
  layout,
  objectFit = 'cover',
  objectPosition = 'center',
  hoverEffect,
  rootRef = null,
  rootMargin = '0px 0px 0px 0px',
  aspectRatio,
  isMaxWidth,
  onLoad,
  srcSetOptions = {},
  sizes = '100vw',
  useSrcSetPattern = false,
}: ImageProps) => {
  // Adjust loading behavior if the image is marked as priority
  if (priority) {
    loading = 'eager';
    loadEffect = 'none';
  }

  const [isLoaded, setIsLoaded] = useState<boolean>(priority);
  const [fadeOutSkeleton, setFadeOutSkeleton] = useState<boolean>(false);

  const [ref, inView] = useInViewRef({
    root: rootRef,
    rootMargin: rootMargin,
    threshold: 0.01,
  });

  const memoizedSrc = useMemo(
    () =>
      determineImageSrc(
        createImageQueryParams(src),
        DEFAULT_IMAGE,
        inView,
        loading
      ),
    [src, inView, loading]
  );

  const memoizedSrcSet = useMemo(() => {
    return createImgSrcSetElement(src, srcSetOptions, inView, loading);
  }, [src, srcSetOptions, inView, loading]);

  const preloadLinks = useMemo(() => {
    if (!priority) return null;

    return createPreloadLinks(
      createImageQueryParams(src),
      sources,
      srcSetOptions,
      sizes,
      useSrcSetPattern
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleImageLoad = useCallback(
    (event: React.SyntheticEvent<HTMLImageElement>) => {
      if (event.currentTarget.src === DEFAULT_IMAGE) return;
      setIsLoaded(true);
      if (onLoad) onLoad();
    },
    [onLoad]
  );

  useEffect(() => {
    if (isLoaded) {
      setFadeOutSkeleton(true);
    }
  }, [isLoaded]);

  if (!src || typeof src !== 'string') {
    return null;
  }

  const isSvgImage = isSvg(src);

  return (
    <>
      {preloadLinks}
      {useSrcSetPattern ? (
        <Img
          ref={ref as React.Ref<HTMLImageElement>}
          src={memoizedSrc}
          alt={alt}
          width={width}
          height={height}
          srcSet={memoizedSrcSet ?? undefined}
          sizes={!isSvgImage && sizes ? sizes : undefined}
          onLoad={handleImageLoad}
          loadEffect={loadEffect}
          layout={layout}
          aspectRatio={aspectRatio}
          objectPosition={objectPosition}
          objectFit={objectFit}
          hoverEffect={hoverEffect}
          className={`${className || ''} ${isLoaded ? 'is-loaded' : ''}`.trim()}
          css={{
            ...(layout === 'responsive' &&
              !aspectRatio &&
              width &&
              height && {
                aspectRatio: `${width} / ${height}`,
              }),
            ...(isMaxWidth && {
              maxWidth: `${width}px`,
            }),
            ...css,
          }}
          data-test="image"
        />
      ) : (
        <Picture
          ref={ref as React.Ref<HTMLPictureElement>}
          layout={layout}
          aspectRatio={aspectRatio}
          className={className}
          css={{
            ...(layout === 'responsive' &&
              !aspectRatio &&
              width &&
              height && {
                aspectRatio: `${width} / ${height}`,
              }),
            ...(isMaxWidth && {
              maxWidth: `${width}px`,
            }),
            ...css,
          }}
        >
          {!!placeholder && (
            <Skeleton
              placeholder={placeholder}
              fadeIn={inView}
              fadeOut={fadeOutSkeleton}
            />
          )}
          {sources?.map((source, index) =>
            createSourceElement(source, index, inView, loading)
          )}
          <Img
            src={memoizedSrc}
            alt={alt}
            width={width}
            height={height}
            objectPosition={objectPosition}
            objectFit={objectFit}
            loadEffect={loadEffect}
            hoverEffect={hoverEffect}
            onLoad={handleImageLoad}
            className={`${isLoaded ? 'is-loaded' : ''}`}
            data-test="image"
          />
        </Picture>
      )}
    </>
  );
};
